import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField, { type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import React, { forwardRef, useState } from 'react';
const NAVIGATION_CODES = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Space'];
const getHasValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value?.length > 0;
  }
  // value 0 is allowed
  if (typeof value === 'number') {
    return true;
  }
  return Boolean(value);
};
export interface TextFieldProps extends Omit<MuiTextFieldProps, 'className'> {
  onEnter?: (e?: React.KeyboardEvent<HTMLDivElement>) => void;
  allowAccessibilityNavigation?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onEscape?: (e?: React.KeyboardEvent<HTMLDivElement>) => void;
}
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({
  variant,
  color,
  onEnter,
  allowAccessibilityNavigation,
  onKeyDown,
  size,
  required,
  error,
  value,
  startAdornment,
  endAdornment,
  onEscape,
  ...rest
}, ref) => {
  const [validationError, setValidationError] = useState(false);
  const hasValue = getHasValue(value);
  const hasError = validationError || error;
  return <MuiTextField ref={ref} color={color} variant={variant} size={size} required={required} error={hasError} value={value} onFocus={() => {
    if (required) setValidationError(false);
  }} onBlur={() => {
    if (required) setValidationError(!hasValue);
  }} onKeyDown={e => {
    if (e.key === 'Escape' && onEscape) {
      onEscape(e);
    }
    if (!allowAccessibilityNavigation && NAVIGATION_CODES.includes(e.code)) {
      e.stopPropagation();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
    if (e.key === 'Enter' && onEnter) {
      onEnter(e);
    }
  }} InputProps={{
    disabled: rest.disabled,
    startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
    endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
    ...rest.InputProps
  }} {...rest} />;
});
TextField.displayName = 'TextField';