import type { ToolWithOverride } from '@magicschool/business-logic';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { getIsMagicStudentEnabled, getTools } from './actions';

export type ToolAccessStore = {
  loading: boolean;
  allToolDetails: ToolWithOverride[];
  isStudentEnabled: boolean | null;
  load: (orgId: string) => Promise<void>;
  reset: () => void;
  setField: SetField<ToolAccessStore>;
};

const defaultState = {
  loading: true,
  isStudentEnabled: null,
  allToolDetails: [] as ToolWithOverride[],
};

export const createToolAccessStoreSlice = createStoreSlice('ToolAccessStoreData', defaultState, ({ set, setField }) => ({
  setField,
  load: async (orgId: string) => {
    set({ loading: true });
    const toolResponse = await getTools({ orgId });
    const studentResponse = await getIsMagicStudentEnabled(orgId);
    const allToolDetails = toolResponse.data || [];
    const isStudentEnabled = Boolean(studentResponse.data);

    set({ allToolDetails, isStudentEnabled, loading: false });
  },
  reset: () => set({ ...structuredClone(defaultState) }),
}));
