'use client';

import { useTheme } from '@mui/material';
import type { ReactNode } from 'react';
import { palette } from '../constants/palette';
import { TEXT_VARIANTS, type TextVariant } from './constants';
const TEXT_COLORS = {
  primary: palette.grey[900],
  secondary: palette.grey[500],
  error: palette.error.main,
  purple: palette.secondary.main,
  textPrimary: palette.text.primary,
  grey600: palette.grey[600],
  grey700: palette.grey[700],
  grey800: palette.grey[800],
  white: 'white',
  inherit: 'inherit'
} as const;
export type TextColor = keyof typeof TEXT_COLORS;
export type TextAlign = 'left' | 'center' | 'right' | 'start' | 'end' | 'inherit';
export interface TextProps {
  variant: TextVariant;
  children: ReactNode;
  color?: TextColor;
  textAlign?: TextAlign;
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | 'inherit';
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit';
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word';
  ellipsis?: boolean;
  multilineEllipsis?: boolean;
  'data-test-id'?: string;
}
const flipTextAlign = (style: TextAlign, isRTL: boolean) => {
  if (isRTL) {
    if (style === 'left') {
      return 'right';
    } else if (style === 'right') {
      return 'left';
    }
  }
  return style;
};
export const Text = ({
  variant,
  children,
  color = 'primary',
  textAlign = 'inherit',
  textTransform = 'none',
  whiteSpace = 'inherit',
  fontStyle = 'normal',
  wordBreak = 'normal',
  ellipsis = false,
  multilineEllipsis = false,
  ...rest
}: TextProps) => {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';
  const {
    Element,
    ...styles
  } = TEXT_VARIANTS[variant];
  const textColor = TEXT_COLORS[color];
  const ellipsisStyles = ellipsis && {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };
  const multilineEllipsisStyles = multilineEllipsis && {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  } as const;
  return <Element style={{
    fontFamily: 'Inter',
    color: textColor,
    textAlign: flipTextAlign(textAlign, isRTL),
    textTransform,
    whiteSpace,
    fontStyle,
    wordBreak,
    ...ellipsisStyles,
    ...multilineEllipsisStyles,
    ...styles
  }} {...rest} data-sentry-element="Element" data-sentry-component="Text" data-sentry-source-file="Text.tsx">
      {children}
    </Element>;
};