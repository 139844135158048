export const TEXT_VARIANTS = {
  'heading-1': {
    Element: 'h1',
    fontSize: '1.75rem', // 28px
    lineHeight: '1.5',
    fontWeight: '700',
  },
  'heading-2': {
    Element: 'h2',
    fontSize: '1.5rem', // 24px
    lineHeight: '1.5',
    fontWeight: '700',
  },
  'heading-3': {
    Element: 'h3',
    fontSize: '1.25rem', // 20px
    lineHeight: '1.25',
    fontWeight: '600',
  },
  'heading-4': {
    Element: 'h4',
    fontSize: '1rem', // 16px
    lineHeight: '1.235',
    fontWeight: '600',
  },
  'heading-5': {
    Element: 'h5',
    fontSize: '0.875rem', // 14px
    lineHeight: '1.334',
    fontWeight: '500',
  },
  'heading-6': {
    Element: 'h6',
    fontSize: '0.75rem', // 12px
    lineHeight: '1.2',
    fontWeight: '400',
  },
  'body-1': {
    Element: 'p',
    fontSize: '1rem', // 16px
    lineHeight: '1.334',
    fontWeight: '400',
  },
  'body-2': {
    Element: 'p',
    fontSize: '0.875rem', // 14px
    lineHeight: '1.334',
    fontWeight: '400',
  },
  'display-1': {
    Element: 'p',
    fontSize: '2.125rem', // 34px
    lineHeight: '1.5',
    fontWeight: '700',
  },
  'badge-1': {
    Element: 'p',
    fontSize: '0.75rem', // 10px
    lineHeight: '1.334',
    fontWeight: '700',
  },
  'caption-1': {
    Element: 'p',
    fontSize: '0.75rem', // 12px
    lineHeight: '1.334',
    fontWeight: '400',
  },
  'caption-2': {
    Element: 'p',
    fontSize: '0.625rem', // 10px
    lineHeight: '1.334',
    fontWeight: '400',
  },
  'label-1': {
    Element: 'p',
    fontSize: '0.875rem', // 14px
    lineHeight: '1.334',
    fontWeight: '700',
  },
} as const;

export type TextVariant = keyof typeof TEXT_VARIANTS;

export const getTextStyles = (variant: TextVariant) => {
  const { Element, ...rest } = TEXT_VARIANTS[variant];
  return rest;
};
