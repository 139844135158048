import type { FormElementProps } from '@/components/FormBuilder/FormElements/types';
import type { ToolWithPublishedConfigAndCategories } from '@magicschool/business-logic';
import type { AssistantToolTypeEnum, ToolStatus } from '@magicschool/supabase/types';
import type { CreateToolRequest, CreateToolResponse, ToolsListResponse } from 'app/api/admin/tools/route';
import { validateToolConfigFields } from 'features/admin/tools/editToolStore';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import toast from 'react-hot-toast';

export type ToolListStore = {
  searchQuery: string;
  selectedRole: 'student' | 'teacher' | 'all';
  selectedStatus: ToolStatus | 'all';
  hydrate: (tools: ToolWithPublishedConfigAndCategories[]) => void;
  reset: () => void;
  setField: SetField<ToolListStore>;
  allToolDetails: ToolsListResponse;
  createNewTool: () => Promise<void>;
  newTool: {
    title: string;
    slug: string;
    assistantToolType?: `${AssistantToolTypeEnum}`;
    fields: string;
  };
  setToolField: (field: 'title' | 'slug' | 'assistantToolType' | 'fields', value: string | boolean) => void;
  validateNewTool: () => void;
  errors: Record<string, string>;
  saving: boolean;
  newToolModalOpen: boolean;
  selectedLlmModelSlug: string;
};

const emptyFields = () =>
  JSON.stringify([
    {
      component: 'GradeLevel',
      name: 'gradeLevel',
      reuseValue: true,
      initialValue: 'pre-k',
      required: true,
    },
  ]);

const defaultState = {
  searchQuery: '',
  selectedRole: 'all' as const,
  selectedStatus: 'all' as const,
  selectedLlmModelSlug: 'all' as const,
  allToolDetails: [] as ToolsListResponse,
  newTool: {
    title: '',
    slug: '',
    fields: emptyFields(),
  },
  errors: {},
  saving: false,
  newToolModalOpen: false,
};

export const createToolListStoreSlice = createStoreSlice('ToolListStoreData', defaultState, ({ get, set, setField }) => ({
  setField,
  reset: () => set({ ...structuredClone(defaultState) }),
  hydrate: (tools: ToolWithPublishedConfigAndCategories[]) => {
    set({ allToolDetails: tools });
  },
  setToolField: (field, value) => {
    set((s) => ({ newTool: { ...s.newTool, [field]: value } }));
  },
  validateNewTool: () => {
    const { newTool } = get();

    const errors: Record<string, string> = {};
    if (!newTool.title) errors.title = 'Invalid title';
    if (!newTool.slug) errors.slug = 'Invalid slug';

    let parsedFields: FormElementProps[] = [];
    try {
      parsedFields = JSON.parse(newTool.fields);
      if (!Array.isArray(parsedFields)) errors.fields = 'Invalid fields';
    } catch (_err) {
      errors.fields = 'Invalid JSON';
    }

    const fieldError = validateToolConfigFields(parsedFields);
    if (fieldError !== '') errors.fieldError = fieldError;

    set({ errors });
  },
  createNewTool: async () => {
    get().validateNewTool();
    const {
      newTool: { title, slug, fields, assistantToolType },
      errors,
    } = get();
    if (Object.keys(errors).length) return;

    set({ saving: true });

    const payload: CreateToolRequest = {
      title,
      slug,
      assistantToolType,
      fields: JSON.parse(fields),
    };
    // biome-ignore lint/suspicious/noExplicitAny: I need two types, one for success, and one for failure
    await fetch<any>(`/api/admin/tools`, {
      method: 'POST',
      body: JSON.stringify(payload),
      onSuccess: async ({ response, router }) => {
        toast.success('Tool created');
        const result: CreateToolResponse = await response.json();
        router.push(`/admin/tools/${result.slug}`);
        set(structuredClone(defaultState));
      },
      responseErrorHandlers: {
        badRequest: async ({ response }) => {
          const res: { error: string } = await response.json();
          set({ errors: { response: res.error } });
          toast.error('Failed to create tool!');
          set({ saving: false });
        },
        conflict: async ({ response }) => {
          const res: { error: string } = await response.json();
          set({ errors: { response: res.error } });
          toast.error('Failed to create tool! Did you provide a non-unique slug?');
          set({ saving: false });
        },
      },
    });
  },
}));
