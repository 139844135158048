import type {
  UpdateAssistantThreadMessageRequest,
  UpdateAssistantThreadMessageResponse,
} from '@/app/api/assistant_thread_messages/[id]/route';
import type { FormBuilderValues } from '@/components/FormBuilder/FormBuilder';
import type { FileMetadata, SafeToolInfo } from '@magicschool/business-logic/tools';
import { logger } from '@magicschool/logger';
import type { AssistantThreadExemplar, AssistantThreadMessageEdit, Room } from '@magicschool/supabase/types';
import type { ModelSlugs } from '@magicschool/supabase/types';
import type { AssistantThreadExemplarResponse } from 'app/api/assistant_thread_exemplars/[tool_uuid]/route';
import type { CreateAssistantThreadMessageResponse } from 'app/api/assistant_thread_messages/route';
import type { AssistantThreadDetailsResponse } from 'app/api/assistant_threads/[id]/route';
import type { CreateAssistantThreadRequestBody, CreateAssistantThreadResponse } from 'app/api/assistant_threads/route';
import type { AssistantThreadResponse } from 'app/api/links/assistant_threads/[id]/route';
import type { CreateModerationResponse } from 'app/api/moderation/route';
import {
  makeBasicRequestMessage,
  makeBasicResponseMessage,
  makeToolInputMessage,
  makeToolOutputMessage,
} from 'features/assistant/messages';
import type { AssistantData, AssistantThread, BaseMessageContentItem, ImageUrlMap, ThreadMessage } from 'features/assistant/types';
import { useReadStream } from 'features/chat/hooks/useReadStream';
import type { ChatAction, ChatMessage, PromptStarter } from 'features/chat/types';
import { makeImageMessage } from 'features/imageGeneration/util';
import { useStore } from 'features/store';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { applyGradeLevel } from 'features/tools/api';
import { toolPromptStarters } from 'features/tools/promptStarters';
import { useEffect, useState } from 'react';
import { ImmutableMap } from 'util/immutable';
import { v4 as uuid } from 'uuid';
import { detectFlaggedWords } from '../moderation/utils';
import { defaultPromptStarters } from './constants';

export type ChatStore = {
  setField: SetField<ChatStore>;
  thread?: AssistantThread;
  threadId?: number;
  messages: ChatMessage[];
  actions: ChatAction[];
  promptStarters: PromptStarter[];
  modifiers: string[];
  followupSuggestions: string[];
  showActions: boolean;
  locked: boolean;
  loading: boolean;
  disabledInput: boolean;
  isStudent: boolean;
  alwaysShowMessageActions: boolean;
  isRaina: boolean;
  shareDialogOpen: boolean;
  inputsCollapsed: boolean;
  toolDetails: SafeToolInfo | null;
  exemplar: AssistantThreadExemplar | null;
  inputsKey: number;
  deleteMessageModalOpen: boolean;
  messageToDelete: number | null;
  imageUrlMap: ImageUrlMap;
  editedMessageMap: ImmutableMap<number, AssistantThreadMessageEdit[]>;
  loadingModel: boolean;
  messageBeingEditedId: number | null;
  fieldsCurrentlyLoading: string[];
  roomToolId: string;
  showingRainaInitialSuggestions: boolean;
  loadThread: (threadId: number | AssistantThread, prependedMessages?: ChatMessage[]) => Promise<AssistantThread>;
  startNewThread: (params: {
    tool_uuid: string;
    assistantData: AssistantData;
    title?: string;
    customizationId?: string | null;
  }) => Promise<number>;
  clearThread: () => void;
  reloadMessages: (threadId: number) => void;
  startDeleteMessage: (messageId: number) => void;
  deleteMessage: (onDelete?: (messageId: number) => void) => () => Promise<void>;
  clearChatStore: () => void;
  clearInputs: () => void;
  updateModel: (model: ModelSlugs) => Promise<void>;
  inputs: FormBuilderValues;
  loadThreadWithMessages: (threadId: number) => Promise<AssistantThread>;
  loadEditedMessagedInThread: (threadId: number) => Promise<void>;
  load: ({
    threadId,
    toolDetails,
    isStudent,
    isRaina,
    shareToken,
    rainaIntro,
    currentRoom,
    roomToolId,
  }: {
    threadId: number | undefined;
    toolDetails?: SafeToolInfo;
    isStudent?: boolean;
    isRaina?: boolean;
    shareToken?: string;
    rainaIntro?: ChatMessage[];
    currentRoom?: Room | null;
    roomToolId?: string;
  }) => void;
  saveChatCompletion: (args: {
    model: string;
    userId: string;
    threadId: number;
    input: string;
    output: string;
    files?: FileMetadata[];
    tool_config_id: string;
    generationId?: string;
  }) => Promise<CreateAssistantThreadMessageResponse>;
  saveMessages: (messages: ChatMessage[]) => Promise<CreateAssistantThreadMessageResponse>;
  addLocalMessages: (message: ChatMessage) => void;
  saveToolGeneration: (args: {
    model: string;
    userId: string;
    toolSlug: string;
    threadId: number;
    inputs: any;
    output: any;
    tool_config_id: string;
    generationId?: string;
  }) => Promise<CreateAssistantThreadMessageResponse>;
  saveImageGeneration: (args: {
    userId: string;
    threadId: number;
    toolSlug: string;
    fileId: string;
    type?: string;
  }) => Promise<CreateAssistantThreadMessageResponse>;
  sendMessageToModeration: (
    roomId: string,
    threadId: number,
    messageId: number,
    input: string,
    flaggedKeywords: string[],
  ) => Promise<CreateModerationResponse>;
  updateChatMessage: (newContent: string, oldContent: ChatMessage) => Promise<void>;
};

const defaultState = {
  thread: undefined,
  threadId: undefined,
  messages: [],
  actions: [],
  promptStarters: [],
  modifiers: [],
  followupSuggestions: [],
  showActions: true,
  locked: false,
  loading: false,
  disabledInput: false,
  alwaysShowMessageActions: false,
  isRaina: false,
  inputsCollapsed: false,
  toolDetails: null,
  exemplar: null,
  inputs: {},
  inputsKey: 0,
  shareDialogOpen: false,
  isStudent: false,
  deleteMessageModalOpen: false,
  messageToDelete: null,
  imageUrlMap: ImmutableMap<string, string>(),
  loadingModel: false,
  messageBeingEditedId: null,
  editedMessageMap: ImmutableMap<number, AssistantThreadMessageEdit[]>(),
  fieldsCurrentlyLoading: [],
  showingRainaInitialSuggestions: false,
  roomToolId: '',
};

export const createChatStoreSlice = createStoreSlice('ChatStoreData', defaultState, ({ set, get, setField }) => ({
  setField,
  load: async ({ isStudent = false, isRaina = false, toolDetails = null, threadId, shareToken, rainaIntro, currentRoom, roomToolId }) => {
    const patchedTool = isStudent ? toolDetails && applyGradeLevel(toolDetails, currentRoom?.grade_level || 'pre-k') : toolDetails;
    set({
      ...defaultState,
      roomToolId: roomToolId || defaultState.roomToolId,
      loading: true,
      toolDetails: patchedTool,
      threadId,
      isStudent: isStudent,
      inputsCollapsed: Boolean(threadId),
      isRaina,
      promptStarters: toolDetails
        ? [...(toolPromptStarters[toolDetails.tool.id] || []), ...defaultPromptStarters]
        : [...defaultPromptStarters],
      modifiers: ['translate', 'customPrompts'],
    });

    const { loadThread } = get();

    if (shareToken) {
      const response = await fetch<AssistantThreadResponse>(`/api/links/assistant_threads/${shareToken}`);
      if (response.status === 200) {
        const { thread, imageUrlMap } = await response.json();
        const initThread = await loadThread(thread);
        set({
          inputsCollapsed: false,
          disabledInput: true,
          inputs: initThread.assistant_thread_messages[0]?.content[0]?.inputs,
          imageUrlMap: ImmutableMap(imageUrlMap),
        });
      }
    } else if (threadId) {
      const fullThread = await loadThread(threadId, rainaIntro);

      // it is possible to receive a SupabaseError, (i.e. fullThread.error) message when a thread does not exist
      if (!Object.hasOwn(fullThread, 'error')) {
        set({ inputsCollapsed: true, inputs: fullThread.assistant_thread_messages[0]?.content[0]?.inputs });
      }
    }

    if (!threadId && rainaIntro) {
      set({ messages: rainaIntro });
    }
    set({ loading: false });

    if (toolDetails?.tool.id) {
      await fetch<AssistantThreadExemplarResponse>(`/api/assistant_thread_exemplars/${toolDetails.tool.id}`, {
        onSuccess: async ({ response }) => {
          const exemplar = await response.json();
          set({ exemplar });
        },
        responseErrorHandlers: {
          notFound: () => {
            logger.info('ToolId is set but no Exemplar found for toolId', toolDetails.tool.slug);
          },
          forbidden: () => ({ shortCircuit: true }),
          unauthorized: () => ({ shortCircuit: true }),
        },
      });
    }
  },
  clearChatStore: () => set(defaultState),
  clearThread: () => set({ threadId: undefined, messages: [], actions: [], disabledInput: false }),
  loadThread: async (thread: number | AssistantThread, prependedMessages: ChatMessage[] = []) => {
    const threadData = typeof thread !== 'object' ? await get().loadThreadWithMessages(thread) : thread;
    try {
      threadData.assistant_thread_messages.sort((a, b) => (a.id > b.id ? 1 : -1));
    } catch (e: any) {
      logger.error('Error in loadThread sorting assistant_thread_messages', e, thread, threadData);
    }

    // There are weird scenarios where the threadData is not iterable
    // Do we want to notify the user of this through a toast or something?
    let assistantThreadMessages = threadData?.assistant_thread_messages;
    if (!assistantThreadMessages || !Array.isArray(assistantThreadMessages)) {
      logger.error('Error in loadThread, threadData is not iterable', threadData);
      assistantThreadMessages = [];
    }

    set({
      messages: [...prependedMessages, ...assistantThreadMessages] as ChatMessage[],
      threadId: threadData.id,
      thread: threadData,
    });
    return threadData;
  },
  startNewThread: async ({ tool_uuid, assistantData, title, customizationId }) => {
    const { clearThread, loadThread } = get();
    clearThread();
    const request: CreateAssistantThreadRequestBody = { assistantData, title, tool_uuid, customizationId };
    const response = await fetch<CreateAssistantThreadResponse>(`/api/assistant_threads`, {
      method: 'POST',
      body: JSON.stringify(request),
    });
    const thread = await response.json();
    await loadThread(thread.id);
    return thread.id;
  },
  reloadMessages: async (threadId: number) => {
    const threadData = await get().loadThreadWithMessages(threadId);
    try {
      threadData.assistant_thread_messages.sort((a, b) => (a.id > b.id ? 1 : -1));
    } catch (e: any) {
      logger.warn('Error in reloadMessages sorting assistant_thread_messages', e, threadId, threadData);
    }
    set({ messages: threadData.assistant_thread_messages as ChatMessage[] });
  },
  clearInputs: () => {
    set((s) => ({
      inputs: {},
      inputsKey: s.inputsKey + 1,
    }));
  },
  loadThreadWithMessages: async (threadId: number) => {
    const response = await fetch<AssistantThreadDetailsResponse>(`/api/assistant_threads/${threadId}`);
    const data = await response.json();

    if (!Object.hasOwn(data, 'error')) {
      const { imageUrlMap, ...thread } = data.thread;

      set({ imageUrlMap: ImmutableMap(imageUrlMap), editedMessageMap: ImmutableMap(data.editedMessageMap) });
      // There is some problematic typing going on with Assistant Threads, notably Assistant Thread Resources. We should revisit this if we have a moment but for now this will work :/
      return thread as unknown as AssistantThread;
    }

    return data as unknown as AssistantThread;
  },
  loadEditedMessagedInThread: async (threadId: number) => {
    const response = await fetch<AssistantThreadDetailsResponse>(`/api/assistant_threads/${threadId}/edits`);
    const data = await response.json();
    set({ editedMessageMap: ImmutableMap(data.editedMessageMap) });
  },
  saveMessages: async (messages) => {
    const res = await fetch<CreateAssistantThreadMessageResponse>(`/api/assistant_thread_messages`, {
      method: 'POST',
      body: JSON.stringify({ messages }),
    });
    const data = await res.json();
    return data;
  },
  addLocalMessages: (message) => {
    set((s) => ({
      messages: [...s.messages, message],
    }));
  },
  saveChatCompletion: ({ model, userId, threadId, input, output, files, tool_config_id, generationId }) => {
    const { saveMessages } = get();
    const userMessage = makeBasicRequestMessage(input, userId, threadId, files);
    const assistantMessage = makeBasicResponseMessage({ output, model, userId, threadId, tool_config_id, generationId });

    return saveMessages([userMessage, assistantMessage]);
  },
  saveToolGeneration: ({ model, userId, toolSlug, threadId, inputs, output, tool_config_id, generationId }) => {
    const { saveMessages } = get();
    const userMessage = makeToolInputMessage(inputs, userId, threadId, toolSlug);
    const assistantMessage = makeToolOutputMessage({ output, model, userId, threadId, toolSlug, tool_config_id, generationId });
    return saveMessages([userMessage, assistantMessage]);
  },
  saveImageGeneration: ({ userId, threadId, toolSlug, fileId, type }) => {
    const { saveMessages } = get();
    const userMessage = makeImageMessage(userId, threadId, fileId);
    const assistantMessage = makeToolInputMessage({ type }, userId, threadId, toolSlug);

    return saveMessages([userMessage, assistantMessage]);
  },
  startDeleteMessage: (messageId: number) => {
    set({ deleteMessageModalOpen: true, messageToDelete: messageId });
  },
  deleteMessage: (onDelete) => async () => {
    const { messageToDelete } = get();
    if (!messageToDelete) return;
    logger.debug('deleteMessage', messageToDelete);
    const response = await fetch(`/api/assistant_thread_messages/${messageToDelete}`, { method: 'DELETE' });

    if (response.status === 200) {
      const messages = get().messages.filter((m) => m.id !== messageToDelete);
      set({ messages, deleteMessageModalOpen: false });
      onDelete?.(messageToDelete);
    }
  },
  sendMessageToModeration: async (roomId, threadId, messageId, input, flaggedKeywords) => {
    const flaggedWords = detectFlaggedWords(input, flaggedKeywords);

    // Retry up to 3 times if the request fails
    let error: Error | null = null;
    for (let i = 0; i < 3; i++) {
      try {
        const res = await fetch<CreateModerationResponse>(`/api/moderation`, {
          method: 'POST',
          body: JSON.stringify({ roomId, threadId, messageId, input, flaggedWords }),
          responseErrorHandlers: {
            // these shouldn't present an error to the user
            badRequest: () => ({ shortCircuit: true }),
            conflict: () => ({ shortCircuit: true }),
            unauthorized: () => ({ shortCircuit: true }),
            forbidden: () => ({ shortCircuit: true }),
            unknown: () => ({ shortCircuit: true }),
          },
        });
        const data = await res.json();
        return data;
      } catch (e) {
        error = e as Error;
        continue;
      }
    }

    // If we've retried 3 times and still failed, throw an error
    logger.error('Failed to send message to moderation', { roomId, threadId, messageId, input, flaggedWords, error });
    throw new Error('Failed to send message to moderation');
  },
  updateModel: async (model: ModelSlugs) => {
    const { isRaina } = get();
    // we're only allowing model selection on Raina for now
    if (!isRaina) return;
    set({ loadingModel: true });
    await fetch(`/api/generations/tool_model`, {
      method: 'POST',
      body: JSON.stringify({ toolId: 'raina', model }),
    });
    set({ loadingModel: false });
  },
  updateChatMessage: async (newContent, oldMessage) => {
    set({ loading: true });
    const { messageBeingEditedId, loadThread } = get();

    // Will assuming it is one item come back to bite us in the butt eventually? probably
    const oldContentArray = oldMessage.content;
    const oldContent = oldContentArray[0];

    // If message type is tool_output, we need to update the output field, otherwise update the text field
    const newContentArray = [
      { ...oldContent, [oldContent.type === 'tool_output' ? 'output' : 'text']: newContent, edited: true },
    ] satisfies BaseMessageContentItem[];

    await fetch<UpdateAssistantThreadMessageResponse>(`/api/assistant_thread_messages/${messageBeingEditedId}`, {
      method: 'PATCH',
      body: JSON.stringify({ content: newContentArray } satisfies UpdateAssistantThreadMessageRequest),
    });

    await loadThread(oldMessage.assistant_thread_id!);
    set({ messageBeingEditedId: null, loading: false });
  },
}));

// biome-ignore lint/complexity/noBannedTypes: <explanation>
export function useReceiveMessageStream(): [Function, boolean] {
  const { threadId, messages, setField } = useStore(
    ({ ChatStoreData: s }) => ({
      threadId: s.threadId,
      messages: s.messages,
      setField: s.setField,
    }),
    [],
  );
  const [readStream, currentOutput, streaming] = useReadStream();
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  const [messageConstructor, setMessageConstructor] = useState<Function>(() => makeBasicResponseMessage);
  const id = uuid(); // provisional id while we stream

  useEffect(() => {
    if (currentOutput) {
      const receivingMessageExists = messages.length > 0 && !!messages[messages.length - 1].receiving;
      const newMessages = receivingMessageExists ? [...messages.slice(0, -1)] : [...messages];
      const streamedMessage = { id, ...messageConstructor({ output: currentOutput }), assistant_thread_id: threadId };
      setField('messages')([...newMessages, { ...streamedMessage, receiving: streaming ? true : undefined }]);
    }
  }, [currentOutput, streaming, threadId]);

  const receiveMessageStream = async (stream: ReadableStream, messageConstructor: (output: string) => ThreadMessage) => {
    setMessageConstructor(() => messageConstructor);
    const output = await readStream(stream);
    return output;
  };

  return [receiveMessageStream, streaming];
}
