'use client';

import { Link } from '@/components/Link';
import { Box } from '@magicschool/ui/Box';
import { Button } from '@magicschool/ui/Buttons/Button';
import { Text } from '@magicschool/ui/Text';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
export default function NotFound() {
  return <Box display="flex" alignItems="center" justifyContent="center" height="100dvh" padding={2} data-sentry-element="Box" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={5} maxWidth={600} width="100%" data-sentry-element="Box" data-sentry-source-file="not-found.tsx">
        <Image priority src="/assets/images/logos/angled/free.png" alt="MagicSchool.ai Logo" height={100} width={300} style={{
        height: 'auto'
      }} data-sentry-element="Image" data-sentry-source-file="not-found.tsx" />
        <Text variant="heading-2" textAlign="center" data-sentry-element="Text" data-sentry-source-file="not-found.tsx">
          <FormattedMessage id="page-does-not-exist" data-sentry-element="FormattedMessage" data-sentry-source-file="not-found.tsx" />
        </Text>
        <Link href="/" data-sentry-element="Link" data-sentry-source-file="not-found.tsx">
          <Button variant="contained" ordinal="secondary" size="large" data-sentry-element="Button" data-sentry-source-file="not-found.tsx">
            <FormattedMessage id="return-home" data-sentry-element="FormattedMessage" data-sentry-source-file="not-found.tsx" />
          </Button>
        </Link>
      </Box>
    </Box>;
}