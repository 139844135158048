import type { Theme } from '@mui/material/styles';
import { handleBreakpoints } from '@mui/system';
import { deepmerge } from '@mui/utils';
import convertToRTL from 'rtl-css-js';
import { BASE_SPACE } from './constants';
type StyleValue = string | number | null | undefined;

export type CSSObject = {
  [key: string]: StyleValue | CSSObject;
};

const SPACING_PROPERTY_MAP: Record<string, string[]> = {
  m: ['margin'],
  margin: ['margin'],
  ml: ['marginLeft'],
  marginLeft: ['marginLeft'],
  mr: ['marginRight'],
  marginRight: ['marginRight'],
  mx: ['marginLeft', 'marginRight'],
  marginX: ['marginLeft', 'marginRight'],
  p: ['padding'],
  padding: ['padding'],
  pl: ['paddingLeft'],
  paddingLeft: ['paddingLeft'],
  pr: ['paddingRight'],
  paddingRight: ['paddingRight'],
  px: ['paddingLeft', 'paddingRight'],
  paddingX: ['paddingLeft', 'paddingRight'],
  marginInline: ['marginInline'],
  marginInlineStart: ['marginInlineStart'],
  marginInlineEnd: ['marginInlineEnd'],
  marginBlock: ['marginBlock'],
  marginBlockStart: ['marginBlockStart'],
  marginBlockEnd: ['marginBlockEnd'],
  paddingInline: ['paddingInline'],
  paddingInlineStart: ['paddingInlineStart'],
  paddingInlineEnd: ['paddingInlineEnd'],
  paddingBlock: ['paddingBlock'],
  paddingBlockStart: ['paddingBlockStart'],
  paddingBlockEnd: ['paddingBlockEnd'],
};

// const SHORTHAND_PROPERTIES_KEYS = Object.keys(SHORTHAND_PROPERTIES);

function isSpacingProperty(property: string): boolean {
  return SPACING_PROPERTY_MAP[property] !== undefined;
}

function applyBaseSpace(value: number | string): number | string {
  if (typeof value === 'string') {
    return value;
  }
  return value * BASE_SPACE;
}

function convertSpacingProperty(props: CSSObject, property: string, value: number | string | CSSObject): CSSObject {
  const mapToProperties = SPACING_PROPERTY_MAP[property]!;

  if (typeof value === 'number' || typeof value === 'string') {
    // If is a space number, use the space function
    const convertedValue = applyBaseSpace(value);
    return mapToProperties.reduce(
      (acc, prop) => {
        acc[prop] = convertedValue;
        return acc;
      },
      {} as Record<string, number | string>,
    );
  } else if (typeof value === 'object') {
    // This is a breakpoint object
    // This function will allow the handleBreakpoints function to work with the spacing properties
    const convertFunction = (finalValue: number | string) => {
      const convertedValue = applyBaseSpace(finalValue);
      return mapToProperties.reduce(
        (acc, prop) => {
          acc[prop] = convertedValue;
          return acc;
        },
        {} as Record<string, number | string>,
      );
    };
    return handleBreakpoints(props, value, convertFunction);
  } else {
    return value;
  }
}

function processCssObject(styles: CSSObject): CSSObject {
  let processedStyles: CSSObject = {};

  for (const [key, value] of Object.entries(styles)) {
    if (isSpacingProperty(key)) {
      processedStyles = deepmerge(processedStyles, convertSpacingProperty(processedStyles, key, value as number));
    } else {
      processedStyles[key] = value;
    }
  }

  return processedStyles;
}

// Takes a CSS emotion object and converts it to RTL
export function convertCssObjectToRtl(styles: CSSObject, isRTL: boolean): CSSObject {
  if (typeof styles !== 'object' || styles === null) {
    // If the input is not an object, return it as-is
    return styles as CSSObject;
  }

  const processedStyles = processCssObject(styles);

  if (!isRTL) {
    return processedStyles;
  }

  const convertedStyles = convertToRTL(processedStyles);

  return convertedStyles;
}

export function convertCssObjectToRtlConditionally(styles: CSSObject, theme: Theme): CSSObject {
  // console.log('cc', JSON.stringify(styles), convertCssObjectToRtl(styles, theme));
  return convertCssObjectToRtl(styles, theme.direction === 'rtl');
}
