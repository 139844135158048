'use client';

import { Link } from '@/components/Link';
import AmplitudeInitializer from '@/components/amplitude/AmplitudeInitializerClient';
import { LocalesProvider } from '@/components/ui-component/LocalesProviderClient';
import { analyticsTrack } from '@/util/analytics';
import { getCookieValue } from '@/util/cookies';
import type { Language } from '@magicschool/supabase/types';
import { Box } from '@magicschool/ui/Box';
import { Button } from '@magicschool/ui/Buttons/Button';
import { Stack } from '@magicschool/ui/Stack';
import { Text } from '@magicschool/ui/Text';
import { space } from '@magicschool/ui/constants/space';
import { appTheme } from '@magicschool/ui/constants/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleTagManager } from '@next/third-parties/google';
import { captureException } from '@sentry/nextjs';
import Image from 'next/image';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
type TrackError = {
  errorMessage: string;
  locale: string | null;
  digest?: string;
  requestUri?: string;
  search?: string;
  hash?: string;
};
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  const theme = useTheme();
  const localeCookie = getCookieValue('locale');
  useEffect(() => {
    const err: TrackError = {
      errorMessage: error.message,
      digest: error.digest,
      locale: localeCookie
    };
    if (typeof window !== 'undefined') {
      // since the url doesn't actually change, we can just look at the current window.location
      err.requestUri = window.location.href;
      err.search = window.location.search;
      err.hash = window.location.hash;
      try {
        analyticsTrack('GlobalError:Error', err);
      } catch (e) {
        console.error('Error tracking error analytics', e);
      }
    }
    captureException(error);
  }, [error, localeCookie]);
  const resetPage = () => {
    location.reload();
  };
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <LocalesProvider locale={localeCookie as Language} data-sentry-element="LocalesProvider" data-sentry-source-file="global-error.tsx">
          <ThemeProvider theme={appTheme} data-sentry-element="ThemeProvider" data-sentry-source-file="global-error.tsx">
            <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="global-error.tsx" />
            <Box display="flex" alignItems="center" justifyContent="center" height="100dvh" padding={2} data-sentry-element="Box" data-sentry-source-file="global-error.tsx">
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2} maxWidth={600} width="100%" data-sentry-element="Box" data-sentry-source-file="global-error.tsx">
                <Image src="/bunny-magic-right.gif" priority={true} width={350} height={217} style={{
                height: 'auto'
              }} alt="Teachers are magic banner" data-sentry-element="Image" data-sentry-source-file="global-error.tsx" />
                <Text variant="heading-2" textAlign="center" data-sentry-element="Text" data-sentry-source-file="global-error.tsx">
                  <FormattedMessage id="something-went-wrong" data-sentry-element="FormattedMessage" data-sentry-source-file="global-error.tsx" />
                </Text>

                <Button variant="contained" ordinal="secondary" size="large" onClick={resetPage} data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
                  <FormattedMessage id="try-again" data-sentry-element="FormattedMessage" data-sentry-source-file="global-error.tsx" />
                </Button>

                <Link href="/" data-sentry-element="Link" data-sentry-source-file="global-error.tsx">
                  <Button variant="outlined" ordinal="secondary" size="large" data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
                    <FormattedMessage id="return-home" data-sentry-element="FormattedMessage" data-sentry-source-file="global-error.tsx" />
                  </Button>
                </Link>
                <Stack data-sentry-element="Stack" data-sentry-source-file="global-error.tsx">
                  <Text variant="caption-1" data-sentry-element="Text" data-sentry-source-file="global-error.tsx">
                    <FormattedMessage id="hard-reset" values={{
                    Link: chunks => <Link href={process.env.NEXT_PUBLIC_CLEAR_COOKIES_URL || ''} target="_blank" style={{
                      textDecoration: 'underline',
                      marginLeft: space(0.5),
                      color: theme.palette.secondary.main
                    }}>
                            {chunks}
                          </Link>
                  }} data-sentry-element="FormattedMessage" data-sentry-source-file="global-error.tsx" />
                  </Text>
                </Stack>
              </Box>
            </Box>
          </ThemeProvider>
        </LocalesProvider>
        <AmplitudeInitializer data-sentry-element="AmplitudeInitializer" data-sentry-source-file="global-error.tsx" />
        <GoogleTagManager preview={process.env.NEXT_PUBLIC_GTM_PREVIEW} auth={process.env.NEXT_PUBLIC_GTM_AUTH} gtmId={process.env.NEXT_PUBLIC_GTM_TAG_ID || ''} data-sentry-element="GoogleTagManager" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}