import { useStore } from '@/features/store';
import { analyticsTrack } from '@/util/analytics';
import { Stack } from '@magicschool/ui/Stack';
import { Text } from '@magicschool/ui/Text';
import { radius } from '@magicschool/ui/constants/radius';
import { space } from '@magicschool/ui/constants/space';
import { getStudentUrl } from '@magicschool/utils/nextjs/url';
import ButtonBase from '@mui/material/ButtonBase';
import { darken, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { FormattedMessage } from 'react-intl';
import { LegalInfoClient } from './LegalInfoClient';
const StyledRoleCard = styled(ButtonBase)(({
  theme
}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: space(4),
  padding: space(6, 7),
  width: 250,
  background: theme.palette.grey[50],
  borderRadius: radius[1],
  border: `1px solid ${theme.palette.grey[300]}`,
  transition: 'all 0.3s',
  '&:hover': {
    background: darken(theme.palette.grey[50], 0.05),
    borderColor: darken(theme.palette.grey[300], 0.05)
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: space(2, 3),
    gap: space(1)
  }
}));
type RoleCardProps = {
  icon: string;
  role: 'student' | 'educator';
  onClick?: () => void;
};
const RoleCard = ({
  icon,
  role,
  onClick
}: RoleCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const iconSize = isMobile ? 50 : 100;
  return <StyledRoleCard onClick={onClick} data-sentry-element="StyledRoleCard" data-sentry-component="RoleCard" data-sentry-source-file="RoleStep.tsx">
      <Image src={`/icons/icon-${icon}.svg`} alt={role} width={iconSize} height={iconSize} data-sentry-element="Image" data-sentry-source-file="RoleStep.tsx" />
      <Stack gap={isMobile ? 0 : 1} data-sentry-element="Stack" data-sentry-source-file="RoleStep.tsx">
        <Text variant={isMobile ? 'heading-4' : 'heading-3'} textAlign="center" data-sentry-element="Text" data-sentry-source-file="RoleStep.tsx">
          <FormattedMessage id={role === 'student' ? 'signup-role.student-card.title' : 'signup-role.educator-card.title'} data-sentry-element="FormattedMessage" data-sentry-source-file="RoleStep.tsx" />
        </Text>

        <Text variant={isMobile ? 'heading-5' : 'body-1'} textAlign="center" data-sentry-element="Text" data-sentry-source-file="RoleStep.tsx">
          <FormattedMessage id={role === 'student' ? 'signup-role.student-card.body' : 'signup-role.educator-card.body'} data-sentry-element="FormattedMessage" data-sentry-source-file="RoleStep.tsx" />
        </Text>
      </Stack>
    </StyledRoleCard>;
};
export const RoleStep = () => {
  const setField = useStore(({
    AuthFormStoreData: af
  }) => af.setField, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const router = useRouter();
  const onStudentClick = () => {
    analyticsTrack('SignIn:StudentRoleClicked');
    router.push(getStudentUrl());
  };
  return <Stack sx={{
    width: {
      xs: '90%',
      md: '600px'
    }
  }} marginTop={isMobile ? 3 : 6} gap={isMobile ? 2 : 4} data-sentry-element="Stack" data-sentry-component="RoleStep" data-sentry-source-file="RoleStep.tsx">
      <Text variant={isMobile ? 'heading-3' : 'heading-2'} textAlign="center" data-sentry-element="Text" data-sentry-source-file="RoleStep.tsx">
        <FormattedMessage id="signup-role.header" data-sentry-element="FormattedMessage" data-sentry-source-file="RoleStep.tsx" />
      </Text>

      <Stack direction={isMobile ? 'column' : 'row'} justifyContent="center" gap={isMobile ? 2 : 10} marginBottom={2} data-sentry-element="Stack" data-sentry-source-file="RoleStep.tsx">
        <RoleCard icon="backpack" role="student" onClick={onStudentClick} data-sentry-element="RoleCard" data-sentry-source-file="RoleStep.tsx" />
        <RoleCard icon="briefcase" role="educator" onClick={() => setField('roleSelected')(true)} data-sentry-element="RoleCard" data-sentry-source-file="RoleStep.tsx" />
      </Stack>
      <LegalInfoClient location="RoleStep" data-sentry-element="LegalInfoClient" data-sentry-source-file="RoleStep.tsx" />
    </Stack>;
};