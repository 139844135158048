import { MUILink, type LinkProps as MUILinkProps } from '@magicschool/ui/MUILink';
import NextLink from 'next/link';
import type { LinkProps as NextLinkProps } from 'next/link';
import { forwardRef } from 'react';
export interface LinkProps extends Omit<NextLinkProps & MUILinkProps, 'legacyBehavior' | 'component' | 'href'> {
  href: NextLinkProps['href'];
}
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(({
  href,
  ...rest
}, ref) => {
  const hrefString = typeof href === 'string' ? href : undefined;
  const isExternal = hrefString?.startsWith('http');
  if (isExternal) {
    return <MUILink href={hrefString} ref={ref} {...rest} />;
  }

  // The `as string` here is to satisfy MUILink's definition of href as string | undefined
  // But because NextLink is passed in as the component, it will handle the href as an object as well
  return <MUILink component={NextLink} color="secondary" {...rest} href={hrefString ?? href as string} />;
});