export const BASE_SPACE = 8;

export const SPACE = [0, 0.25, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 11, -0.25, -0.5, -1, -1.5, -2, -2.5] as const;

export type Space = (typeof SPACE)[number];

export const space = (...values: Space[]): string => {
  return values
    .map((value) => {
      const multipliedValue = value * BASE_SPACE;
      return `${multipliedValue}px`;
    })
    .join(' ');
};
