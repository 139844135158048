'use client';

import { initAmplitude } from '@/util/analytics';
import { logger } from '@magicschool/logger';
import { useEffect, useState } from 'react';
const AmplitudeInitializer: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (isInitialized) return;
    try {
      initAmplitude();
      setIsInitialized(true);
      logger.info('Amplitude initialized');
    } catch (error) {
      logger.error('Failed to initialize/update analytics:', error);
    }
  }, [isInitialized]);
  return null;
};
export default AmplitudeInitializer;