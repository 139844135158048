import MuiLink, { type LinkProps as MuiLinkProps } from '@mui/material/Link';
import { type ReactNode, forwardRef } from 'react';
export interface LinkProps extends MuiLinkProps {
  children: ReactNode;
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLAnchorElement>;
}
export const MUILink = forwardRef<HTMLAnchorElement, LinkProps>(({
  children,
  ...rest
}, ref) => {
  return <MuiLink ref={ref} {...rest}>
      {children}
    </MuiLink>;
});