import createTypography from '@mui/material/styles/createTypography';
import { getTextStyles } from '../../Text';
import { palette } from '../palette';

export const typography = createTypography(palette, (palette) => ({
  fontFamily: 'Inter',
  'display-1': {
    ...getTextStyles('display-1'),
    color: palette.grey[900],
  },
  h1: {
    ...getTextStyles('heading-1'),
    color: palette.grey[900],
  },
  h2: {
    ...getTextStyles('heading-2'),
    color: palette.grey[900],
  },
  h3: {
    ...getTextStyles('heading-3'),
    color: palette.grey[900],
  },
  h4: {
    ...getTextStyles('heading-4'),
    color: palette.grey[900],
  },
  h5: {
    ...getTextStyles('heading-5'),
    color: palette.grey[900],
  },
  h6: {
    ...getTextStyles('heading-6'),
    color: palette.grey[900],
  },
  body1: {
    ...getTextStyles('body-1'),
    color: palette.grey[900],
  },
  body2: {
    ...getTextStyles('body-2'),
    color: palette.grey[900],
  },
  caption: {
    ...getTextStyles('caption-1'),
    color: palette.grey[900],
  },
  'caption-2': {
    ...getTextStyles('caption-2'),
    color: palette.grey[900],
  },
  'badge-1': {
    ...getTextStyles('badge-1'),
    color: palette.grey[900],
  },
  'label-1': {
    ...getTextStyles('label-1'),
    color: palette.grey[900],
  },
  button: {
    textTransform: 'capitalize',
  },

  subtitle1: undefined,
  subtitle2: undefined,

  overline: undefined,
}));
