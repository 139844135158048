import { UserRole } from '@magicschool/supabase/types';
import { subscriptionPlans } from '@magicschool/supabase/user/utils';
import { getTrialDaysLeft, signupTrialAppliesToUser } from '../trials';
import { partnerFeatures, plusFeatures } from './constants';
import type { FeatureFlagProps, FeatureFlags } from './types';

export const getFeatureFlags = ({
  user,
  planId,
  orgAttributes,
  siteFeatures,
  userAttributes,
  isStudent,
}: FeatureFlagProps): FeatureFlags => {
  const loading = !user || Boolean(user?.org_id && orgAttributes === null) || userAttributes === null;
  let featureFlags: FeatureFlags = {
    loading: loading,
    planId: 1,
    canSubscribe: false,
    trialAccess: false,
    trialDaysLeft: 0,
    fullHistory: false,
    rainaCustomizations: isStudent ?? false,
    chatExports: false,
    tosUpdatesAcked: [],
    isMagicSchoolEmployee: Boolean(user?.email?.includes('@magicschool.ai')),
    userRole: user?.user_role ?? UserRole.teacher,
    isOrgAdmin: user?.user_role === UserRole.org_admin,
    isInternal: user?.user_role === UserRole.internal,
    isSuperUser: user?.user_role === UserRole.superuser,
    rainaModel: null,
    adminAccess: user?.user_role === UserRole.superuser,
    enableRequireLogin: false,
    requireLoginDefault: false,
    edlinkSyncEnabled: false,
    teamToolsEnabled: false,
    ...siteFeatures,
  };

  // If we don't have the user yet, we can't check overrides, so we return default features
  if (!user || !user.id) return featureFlags;
  if (!user.created_at) console.error("This user doesn't have a created_at date!", user.id);

  featureFlags.planId = planId ?? 1;

  switch (featureFlags.planId) {
    case subscriptionPlans.FREE:
      featureFlags.canSubscribe = true;

      if (signupTrialAppliesToUser(user.created_at)) {
        featureFlags.trialAccess = true;
        featureFlags.trialDaysLeft = getTrialDaysLeft(user.created_at);
        featureFlags = { ...featureFlags, ...plusFeatures };

        if (!siteFeatures?.magicStudentEnabled) {
          // we're launching magic student to plus subs, but not free trial users
          // once we launch magic student to everyone, this be true and we can
          // come back here and remove this code.
          featureFlags.magicStudentEnabled = false;
        }
      }
      break;

    case subscriptionPlans.PLUS:
      featureFlags = { ...featureFlags, ...plusFeatures };
      break;

    case subscriptionPlans.PARTNER:
      featureFlags = { ...featureFlags, ...partnerFeatures };
      break;
  }

  if (featureFlags.isMagicSchoolEmployee) {
    featureFlags.disabledSubscriptions = false;
    featureFlags.magicStudentEnabled = true;
  }

  if (orgAttributes?.length > 0) {
    const enableMagicStudentFlag = orgAttributes.find(
      (attr) => attr.name === 'enable_magic_student' && attr.value === 'true' && attr.active,
    );
    if (enableMagicStudentFlag) {
      featureFlags.magicStudentEnabled = true;
    }

    const disableMagicStudentFlag = orgAttributes.find(
      (attr) => attr.name === 'enable_magic_student' && attr.value === 'false' && attr.active,
    );
    if (disableMagicStudentFlag) {
      featureFlags.magicStudentEnabled = false;
    }

    const enableAdobe = orgAttributes.find((attr) => attr.name === 'enable_adobe' && attr.value === 'true' && attr.active);
    if (enableAdobe) {
      featureFlags.adobeEnabled = true;
    }

    const disableAdobe = orgAttributes.find((attr) => attr.name === 'disable_adobe' && attr.value === 'true' && attr.active);
    if (disableAdobe) {
      featureFlags.adobeEnabled = false;
    }

    const enableEdlinkSync = orgAttributes.find((attr) => attr.name === 'enable_edlink_sync' && attr.value === 'true' && attr.active);
    if (enableEdlinkSync) {
      featureFlags.edlinkSyncEnabled = true;
    }

    const enableRequireLogin = orgAttributes.find((attr) => attr.name === 'enable_require_login' && attr.value === 'true' && attr.active);
    if (enableRequireLogin) {
      featureFlags.enableRequireLogin = true;
    }

    const requireLoginDefault = orgAttributes.find((attr) => attr.name === 'require_login_default' && attr.active);
    if (requireLoginDefault) {
      featureFlags.requireLoginDefault = requireLoginDefault.value === 'true';
    }

    const disableWebsiteImport = orgAttributes.find((attr) => attr.name === 'disable_website_import' && attr.value === 'true');
    if (disableWebsiteImport) {
      featureFlags.websiteImportEnabled = false;
    }
  }

  if (userAttributes && userAttributes.length > 0) {
    const enableMagicStudentFlag = userAttributes.find(
      (attr) => attr.name === 'enable_magic_student' && attr.value === 'true' && attr.active,
    );
    if (enableMagicStudentFlag) {
      featureFlags.magicStudentEnabled = true;
    }

    const enableAdminAccess = userAttributes.find((attr) => attr.name === 'enable_admin_access' && attr.value === 'true' && attr.active);
    if (enableAdminAccess && featureFlags.isMagicSchoolEmployee && (featureFlags.isInternal || featureFlags.isSuperUser)) {
      featureFlags.adminAccess = true;
    }

    const disableMagicStudentFlag = userAttributes.find(
      (attr) => attr.name === 'enable_magic_student' && attr.value === 'false' && attr.active,
    );
    if (disableMagicStudentFlag) {
      featureFlags.magicStudentEnabled = false;
    }

    const enableAdobe = userAttributes.find((attr) => attr.name === 'enable_adobe' && attr.value === 'true' && attr.active);
    if (enableAdobe) {
      featureFlags.adobeEnabled = true;
    }

    const disableAdobe = userAttributes.find((attr) => attr.name === 'disable_adobe' && attr.value === 'true' && attr.active);
    if (disableAdobe) {
      featureFlags.adobeEnabled = false;
    }

    const tosUpdates = userAttributes.filter((attr) => attr.name.startsWith('tosUpdate') && attr.value === 'true' && attr.active);
    for (const tos of tosUpdates) {
      featureFlags.tosUpdatesAcked.push(tos.name);
    }

    const rainaModel = userAttributes.find((attr) => attr.name === 'toolModel-raina');
    if (rainaModel) {
      featureFlags.rainaModel = rainaModel.value as FeatureFlags['rainaModel'];
    }
  }

  if (user.user_role === 'student') {
    featureFlags.magicStudentEnabled = true;
    featureFlags.rainaCustomizations = true;
    featureFlags.pdfUploadEnabled = true;
  }

  if (featureFlags.adminAccess || user.email === 'org_admin1@enterprise.edu') {
    featureFlags.teamToolsEnabled = true;
  }

  return featureFlags;
};
