'use client';

import { useStore } from '@/features/store';
import { Text } from '@magicschool/ui/Text';
import { FormattedMessage } from 'react-intl';
export const LocalizationTag = () => {
  const roleSelected = useStore(({
    AuthFormStoreData: af
  }) => af.roleSelected, []);
  if (roleSelected) return null;
  return <Text variant="body-2" data-sentry-element="Text" data-sentry-component="LocalizationTag" data-sentry-source-file="LocalizationTagClient.tsx">
      <FormattedMessage id="signup-role.localization-tag" data-sentry-element="FormattedMessage" data-sentry-source-file="LocalizationTagClient.tsx" />
    </Text>;
};