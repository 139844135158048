import(/* webpackMode: "eager", webpackExports: ["LanguageThemeProvider"] */ "/vercel/path0/apps/magicschool/src/app/LanguageThemeProviderClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/magicschool/src/components/AccessiBe/AccessiBeClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CleanCookies"] */ "/vercel/path0/apps/magicschool/src/components/CleanCookiesClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/magicschool/src/components/CookieYes/CookieYesClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EdlinkWidgetClient"] */ "/vercel/path0/apps/magicschool/src/components/EdlinkWidget/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalesProvider"] */ "/vercel/path0/apps/magicschool/src/components/ui-component/LocalesProviderClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider"] */ "/vercel/path0/apps/magicschool/src/hooks/useQuery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EnvironmentSensitiveWrapperClient"] */ "/vercel/path0/apps/magicschool/src/layout/MainLayout/EnvironmentSensitiveWrapperClient.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/magicschool/src/styles/fonts.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/magicschool/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material-nextjs@5.16.6_@emotion+cache@11.13.1_@emotion+server@11.11.0_@mui+material@5.16_5soamfhrb2q3rnfi6bfmpfaltq/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react_rbww5lafgikomxmagsjmi4wf4y/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react_rbww5lafgikomxmagsjmi4wf4y/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.5_next@14.2.5_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react_rbww5lafgikomxmagsjmi4wf4y/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["appTheme"] */ "/vercel/path0/packages/ui/src/constants/theme/theme.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/vercel/path0/packages/ui/src/Text/Text.tsx");
