import type { PromptStarter } from './types';

export const defaultPromptStarters: PromptStarter[] = [
  { label: 'prompt_starter.differentiate.label', promptText: 'prompt_starter.differentiate.prompt' },
  { label: 'prompt_starter.rewrite.label', promptText: 'prompt_starter.rewrite.prompt' },
  { label: 'prompt_starter.questions.label', promptText: 'prompt_starter.questions.prompt' },
  { label: 'prompt_starter.resources.label', promptText: 'prompt_starter.resources.prompt' },
];

export const INITIAL_MESSAGE_INTL_ID = 'monitoring-table.initial-generation';
export const IMAGE_EDITED_INTL_ID = 'image-generator.image-edited';

export const FALLBACK_MAX_INPUT_CHARACTER_LENGTH = '450000';
