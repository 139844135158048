import { Box } from '@magicschool/ui/Box';
import { Text } from '@magicschool/ui/Text';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';
export const Loader = () => {
  return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={4} maxWidth={440} height="100%" data-sentry-element="Box" data-sentry-component="Loader" data-sentry-source-file="Loader.tsx">
      <CircularProgress color="secondary" size={60} data-sentry-element="CircularProgress" data-sentry-source-file="Loader.tsx" />
      <Text variant="heading-3" textAlign="center" data-sentry-element="Text" data-sentry-source-file="Loader.tsx">
        <FormattedMessage id="profile-builder.loader.body" data-sentry-element="FormattedMessage" data-sentry-source-file="Loader.tsx" />
      </Text>
    </Box>;
};