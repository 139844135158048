'use client';

import { teal } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { TEXT_VARIANTS } from '../../Text';
import { convertCssObjectToRtl } from '../../utils';
import { palette } from '../palette';
import { radius } from '../radius';
import { shadows } from '../shadows';
import { space } from '../space';
import { typography } from '../typography';

type UnstableSxConfigType = NonNullable<NonNullable<Parameters<typeof createTheme>[0]>['unstable_sxConfig']>[string];

const PROPERTIES_TO_CONVERT = [
  'padding',
  'paddingRight',
  'paddingLeft',
  'margin',
  'marginRight',
  'marginLeft',
  'borderRight',
  'borderLeft',
  'textAlign',
  'justifyContent',
  'm',
  'mx',
  'mr',
  'ml',
  'p',
  'pl',
  'pr',
  'left',
  'right',
] as const;

// Creates a config object for a single property that will convert it to RTL
const createPropertyConverter = (): UnstableSxConfigType => ({
  style: ({ theme, ...props }) => {
    // We need to pass everything through this converter in order to reapply the style effects like media query shorthand, spacing conversion, etc
    // We should come back and rename everything so it makes more sense later on
    return convertCssObjectToRtl(props, theme.direction === 'rtl');
  },
});

export const appTheme = createTheme({
  unstable_sxConfig: {
    ...Object.fromEntries(PROPERTIES_TO_CONVERT.map((key) => [key, createPropertyConverter()])),
  },
  palette: {
    ...palette,
    tertiary: palette.augmentColor({
      name: 'tertiary',
      color: { main: teal[500] },
    }),
  },
  mixins: {
    toolbar: {
      minHeight: '48px',
      padding: space(2),
      '@media (min-width: 600px)': {
        minHeight: '48px',
      },
    },
  },
  typography,
  customShadows: shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: radius[0.5],
          bgColor: 'blue',
        },
        startIcon: ({ theme }) => ({
          marginLeft: theme.direction === 'rtl' ? theme.spacing(1) : theme.spacing(-0.5),
          marginRight: theme.direction === 'rtl' ? theme.spacing(-0.5) : theme.spacing(1),
        }),
        endIcon: ({ theme }) => ({
          marginLeft: theme.direction === 'rtl' ? theme.spacing(-0.5) : theme.spacing(1),
          marginRight: theme.direction === 'rtl' ? theme.spacing(1) : theme.spacing(-0.5),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
          paddingX: 1.5,
          paddingY: 1,
          '&.Mui-selected': { backgroundColor: 'white' },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: radius[1],
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.dark,
          padding: space(3),
        }),
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: space(3),
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: space(3),
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
          alignItems: 'center',
        },
        outlined: {
          border: '1px dashed',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
          color: theme.palette.text.secondary,
          paddingTop: space(1.5),
          paddingBottom: space(1.5),
          '&.Mui-selected': {
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.light,
            '&:hover': {
              backgroundColor: theme.palette.secondary.light,
            },
            '& .MuiListItemIcon-root': {
              color: theme.palette.secondary.dark,
            },
          },
          '&:hover': {
            backgroundColor: 'menuSelectedBack',
            color: theme.palette.secondary.dark,
            '& .MuiListItemIcon-root': {
              color: theme.palette.secondary.dark,
            },
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
          minWidth: '36px',
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: ({ theme }) => ({
          fontSize: TEXT_VARIANTS['body-2'].fontSize,

          color: theme.palette.text.dark,
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: ({ theme }) => ({
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
          color: theme.palette.text.dark,
          '&::placeholder': {
            color: theme.palette.text.secondary,
            fontSize: TEXT_VARIANTS['body-2'].fontSize,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          textAlign: theme.direction === 'rtl' ? 'right' : 'left',
          width: '100%',
          transformOrigin: `top ${theme.direction === 'rtl' ? 'right' : 'left'}`,
        }),
        shrink: ({ theme }) => ({
          transform: `translate(${theme.direction === 'rtl' ? '-16' : '14'}px, -9px) scale(0.75)`,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.grey[50],
          borderRadius: radius[1],
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
          },
          '&:hover $notchedOutline': {
            borderColor: theme.palette.secondary.light,
          },
          '&.MuiInputBase-multiline': {
            padding: 1,
          },
        }),
        input: ({ theme }) => ({
          fontWeight: 500,
          background: theme.palette.grey[50],
          padding: space(2),
          borderRadius: radius[1],
          '&.MuiInputBase-inputSizeSmall': {
            padding: space(1.5, 2),
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
        }),
        inputAdornedStart: {
          paddingLeft: space(0.5),
        },
        notchedOutline: {
          borderRadius: radius[1],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.grey[300],
          },
        }),
        mark: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          width: '4px',
        }),
        valueLabel: ({ theme }) => ({
          color: theme.palette.secondary.light,
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiAutocomplete-tag': {
            background: theme.palette.secondary.light,
            borderRadius: radius[1],
            color: theme.palette.text.dark,
            '.MuiChip-deleteIcon': {
              color: theme.palette.secondary[200],
            },
          },
        }),
        option: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
        },
        noOptions: {
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
        },
        popper: {
          borderRadius: radius[1],
          boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.divider,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontWeight: 500,
          fontSize: TEXT_VARIANTS['body-2'].fontSize,
          borderRadius: radius[0.5],
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        filled: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
          color: 'white',
          '&:focus': {
            backgroundColor: theme.palette.secondary.main,
          },
        }),
        iconFilled: {
          color: 'white',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.dark,
          background: theme.palette.secondary[200],
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: ({ theme }) => ({
          borderBottom: '1px solid',
          borderColor: theme.palette.grey[200],
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: space(1.5, 0),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.grey[200],
          '&.MuiTableCell-head': {
            fontSize: '0.875rem',
            color: theme.palette.grey[900],
            fontWeight: 500,
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          color: theme.palette.background.paper,
          background: theme.palette.text.secondary,
          fontSize: TEXT_VARIANTS['caption-1'].fontSize,
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: ({ theme }) => ({
          right: `calc(${theme.direction === 'rtl' ? '-' : ''}50% + 20px)`,
          left: `calc(${theme.direction === 'rtl' ? '' : '-'}50% + 20px)`,
        }),
      },
    },
  },
});
