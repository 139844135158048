import Logo from '@/components/ui-component/LogoClient';
import { useStore } from '@/features/store';
import { Box } from '@magicschool/ui/Box';
import { Text } from '@magicschool/ui/Text';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
export const Welcome = () => {
  const isNewUser = useStore(({
    ProfileBuilderStoreData: s
  }) => s.isNewUser, []);
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2} paddingX={isFullScreen ? 2 : 7} marginTop={2} height="100%" data-sentry-element="Box" data-sentry-component="Welcome" data-sentry-source-file="Welcome.tsx">
      {isNewUser ? <Logo width={144} variant="free" /> : <Image src="/logo-book.svg" alt="MagicSchool.ai Logo with book" height={100} width={318} style={{
      height: 'auto'
    }} />}
      <Box marginTop={2} data-sentry-element="Box" data-sentry-source-file="Welcome.tsx">
        <Text variant="heading-3" textAlign="center" data-sentry-element="Text" data-sentry-source-file="Welcome.tsx">
          <FormattedMessage id={isNewUser ? 'profile-builder.welcome.title-new' : 'profile-builder.welcome.title-returning'} data-sentry-element="FormattedMessage" data-sentry-source-file="Welcome.tsx" />
        </Text>
      </Box>
      {isNewUser ? <>
          <Text variant="body-1" textAlign="center">
            <FormattedMessage id="profile-builder.welcome.body1" values={{
          b: text => <b>{text}</b>,
          i: text => <i>{text}</i>
        }} />
          </Text>
          <Text variant="body-1" textAlign="center">
            <FormattedMessage id="profile-builder.welcome.body2" values={{
          b: text => <b>{text}</b>
        }} />
          </Text>
          <Text variant="body-1" textAlign="center">
            <FormattedMessage id="profile-builder.welcome.body3" />
          </Text>
        </> : <Text variant="body-1" textAlign="center">
          <FormattedMessage id="profile-builder.welcome.returning" />
        </Text>}
    </Box>;
};