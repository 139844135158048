'use client';

import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { setStudentLocale, setUserLocale } from '@/features/locales/actions';
import { type LocaleData, getLocaleData } from '@/features/locales/getLocaleData';
import type { Language } from '@magicschool/supabase/types';
import { isStudentHost, isTeacherHost } from '@magicschool/utils/nextjs/url';
import { IntlProvider } from 'react-intl';
type LocaleContextType = {
  locale: string;
  onLocaleChange: (locale: Language) => void;
};
const initialState: LocaleContextType = {
  locale: 'en-us',
  // biome-ignore lint/suspicious/noEmptyBlockStatements: This is a placeholder function
  onLocaleChange: () => {}
};
export const LocaleContext = React.createContext(initialState);

// ==============================|| LOCALIZATION ||============================== //
interface LocalsProps {
  locale?: Language;
  children: React.ReactNode;
}
const setLocaleCookie = (locale: Language) => {
  document.cookie = `locale=${locale};path=/;max-age=31536000`;
};
const hasAuthCookie = () => {
  return document.cookie.split('; ').some(row => row.includes('Authorization'));
};
export const LocalesProvider = ({
  children,
  locale = 'en-us'
}: LocalsProps) => {
  const [currentLocale, setCurrentLocale] = useState(locale);
  const [messages, setMessages] = useState<LocaleData | undefined>();
  useEffect(() => {
    const doIt = async () => {
      const data = await getLocaleData(currentLocale);
      if (isStudentHost(window.location.hostname) && window.location.pathname.includes('s/rooms')) {
        // students are always authed within the context of a room
        await setStudentLocale(currentLocale);
      } else if (isTeacherHost(window.location.hostname) && hasAuthCookie()) {
        // teachers have http-only false access, so we can verify they're logged in
        await setUserLocale(currentLocale);
      }
      if (currentLocale) {
        setLocaleCookie(currentLocale);
      }
      setMessages(data);
    };
    doIt();
  }, [currentLocale, setLocaleCookie]);
  const onChangeLocale = useCallback((locale: Language) => {
    setCurrentLocale(locale);
    setLocaleCookie(locale);
  }, [setLocaleCookie]);
  return <>
      {messages && <IntlProvider locale={currentLocale} defaultLocale="en-us" messages={messages}>
          <LocaleContext.Provider value={{
        locale: currentLocale,
        onLocaleChange: onChangeLocale
      }}>{children}</LocaleContext.Provider>
        </IntlProvider>}
    </>;
};