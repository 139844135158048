import type { FormElementProps } from '@/components/FormBuilder/FormElements/types';
import type { SafeToolInfo, ToolCustomizationJsonConfig } from '@magicschool/business-logic/tools';
import type {
  InsertToolCustomization as BaseInsertToolCustomization,
  ToolCustomization as BaseToolCustomization,
  Room,
  RoomTeacher,
  RoomTool,
  ToolApplicationTypeType,
} from '@magicschool/supabase/types';

import type { FullRoomTool } from '@/app/api/rooms/[id]/edit/route';
import { ImmutableMap } from 'util/immutable';

export type ToolCustomization = Omit<BaseToolCustomization, 'json_config'> & {
  json_config: ToolCustomizationJsonConfig;
};

export type InsertToolCustomization = Omit<BaseInsertToolCustomization, 'json_config'> & {
  json_config: ToolCustomizationJsonConfig;
};

export type RoomToolWithCustomizations = RoomTool & { tool_customization: ToolCustomization | null };

export type CreateOrUpdateTool = {
  tool_slug: string;
  tool_uuid: string;
  tool_customization: InsertToolCustomization | null;
  room_tool_id: string;
};

export const toToolCustomization = (tc: BaseToolCustomization): ToolCustomization => {
  return {
    ...tc,
    json_config: tc.json_config as unknown as ToolCustomizationJsonConfig,
  };
};

export const toInsertToolCustomization = (tc: BaseInsertToolCustomization): InsertToolCustomization => {
  return {
    name: tc.name,
    teacher_id: tc.teacher_id,
    tool_slug: tc.tool_slug,
    tool_uuid: tc.tool_uuid,
    json_config: tc.json_config as unknown as ToolCustomizationJsonConfig,
  };
};

export const emptyRoomTool = (args: Partial<RoomTool> = {}): RoomTool => ({
  id: '',
  room_id: '',
  tool_id: '',
  tool_uuid: '',
  created_at: '',
  ...args,
});

export const emptyFullRoomTool = (): FullRoomTool => ({
  room_tool: emptyRoomTool(),
  tool_customization: null,
  toolTitle: '',
  toolDescription: '',
  isDisabled: false,
});

export const emptyToolCustomization = (
  toolDetails: SafeToolInfo,
  defaultGradeLevel: string,
  tool_application: ToolApplicationTypeType = 'room',
  customInstructions = '',
): ToolCustomization => {
  const emptyToolCustomization: ToolCustomization = {
    id: '',
    name: '',
    tool_slug: toolDetails.tool.slug,
    tool_uuid: toolDetails.tool.id,
    teacher_id: '',
    org_id: '',
    json_config: {
      tool_title: toolDetails.tool.title,
      tool_description: toolDetails.tool.description,
      customInstructions,
      inputs: toolDetails.fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: { value: field.initialValue, visibility: 'visible' } }),
        {},
      ),
      voice: 'nova',
    },
    created_at: '',
    updated_at: '',
    tool_application,
    sharing_access_type: 'private',
    sharing_code: '',
    status: 'active',
    rag_assistant_id: null,
  };
  if (emptyToolCustomization.json_config.inputs.gradeLevel && defaultGradeLevel) {
    emptyToolCustomization.json_config.inputs.gradeLevel.value = defaultGradeLevel;
    emptyToolCustomization.json_config.inputs.gradeLevel.visibility = tool_application === 'room' ? 'hidden' : 'visible';
  }
  return emptyToolCustomization;
};

export const generateValidToolCustomization = (
  fields: FormElementProps[],
  existingCustomizations: ToolCustomization,
): ToolCustomization => {
  const updatedInputs = ImmutableMap(
    fields.map((field) => {
      const updatedInput = existingCustomizations.json_config?.inputs?.[field.name] || { value: field.initialValue, visibility: 'visible' };
      return [field.name, updatedInput];
    }),
  );
  return {
    ...existingCustomizations,
    json_config: {
      inputs: updatedInputs.toObject(),
      tool_title: existingCustomizations.json_config.tool_title || undefined,
      tool_description: existingCustomizations.json_config.tool_description,
      voice: existingCustomizations.json_config.voice ?? 'nova',
      customInstructions: existingCustomizations.json_config.customInstructions ?? '',
    },
  };
};

/**
 * Tastes great AND is less filling!
 * A lite version of the Rooms table entity.
 *
 * This is intended for usage in all of the table views or lite summary views of rooms
 * creator_room_teacher: is always the room_teachers row of the creator
 * viewing_room_teacher: could be an admin, coteacher, or pending/rejected coteacher
 *
 * If you need all the expensive stuff (students, tools, moderation, etc), you should use the full RoomDetailsResponse type
 */
export type RoomWithBasics = {
  room: Room;
  id: string;
  student_count: number;
  creator_room_teacher: RoomTeacher;
  viewing_room_teacher: RoomTeacher | null;
};
