'use client';

import GlobalStyles from '@mui/material/GlobalStyles';
import { type ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import type { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';
import { deepmerge } from '@mui/utils';
export const LanguageThemeProvider = ({
  children,
  theme,
  isRtl
}: ThemeProviderProps & {
  isRtl: boolean;
}) => {
  const themeWithDirection = createTheme(deepmerge(theme, {
    direction: isRtl ? 'rtl' : 'ltr'
  }) as ThemeOptions);
  return <ThemeProvider theme={themeWithDirection} data-sentry-element="ThemeProvider" data-sentry-component="LanguageThemeProvider" data-sentry-source-file="LanguageThemeProviderClient.tsx">
      <GlobalStyles styles={theme => ({
      legend: {
        textAlign: theme.direction === 'rtl' ? 'right' : 'left'
      }
    })} data-sentry-element="GlobalStyles" data-sentry-source-file="LanguageThemeProviderClient.tsx" />
      {children}
    </ThemeProvider>;
};