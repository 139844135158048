'use client';

import { Link } from '@/components/Link';
import { analyticsTrack } from '@/util/analytics';
import { Box } from '@magicschool/ui/Box';
import { Text } from '@magicschool/ui/Text';
import { FormattedMessage } from 'react-intl';
export const LegalInfoClient = ({
  location
}: {
  location: 'SignUp' | 'SignIn' | 'RoleStep';
}) => {
  return <Box mb={1} data-sentry-element="Box" data-sentry-component="LegalInfoClient" data-sentry-source-file="LegalInfoClient.tsx">
      <Text variant="body-2" textAlign="center" data-sentry-element="Text" data-sentry-source-file="LegalInfoClient.tsx">
        <FormattedMessage id="auth.agree-to-terms" values={{
        privacyA: text => <Link href="https://www.magicschool.ai/privacy-policy" color="secondary" target="_blank" rel="noopener noreferrer" onClick={() => {
          analyticsTrack(`${location}:PrivacyTermsClicked`, {
            value: 'privacy'
          });
        }}>
                {text}
              </Link>,
        termsA: text => <Link href="https://www.magicschool.ai/terms-of-service" color="secondary" target="_blank" rel="noopener noreferrer" onClick={() => {
          analyticsTrack(`${location}:PrivacyTermsClicked`, {
            value: 'terms'
          });
        }}>
                {text}
              </Link>
      }} data-sentry-element="FormattedMessage" data-sentry-source-file="LegalInfoClient.tsx" />
      </Text>
    </Box>;
};