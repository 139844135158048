'use client';

import { Box } from '@magicschool/ui/Box';
import { Text } from '@magicschool/ui/Text';
import { FormattedMessage } from 'react-intl';
export const AuthHeaderClient = ({
  id
}: {
  id: string;
}) => <Box marginBottom={2} data-sentry-element="Box" data-sentry-component="AuthHeaderClient" data-sentry-source-file="AuthHeaderClient.tsx">
    <Text variant="heading-1" textAlign="center" data-test-id="auth-header" data-sentry-element="Text" data-sentry-source-file="AuthHeaderClient.tsx">
      <FormattedMessage id={id} data-sentry-element="FormattedMessage" data-sentry-source-file="AuthHeaderClient.tsx" />
    </Text>
  </Box>;