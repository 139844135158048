// Yes, I know this is copied code but I don't want to create a circular dependency

import { UserRole, type UserRoleType } from '../types/db';

export const subscriptionPlans = {
  FREE: 1,
  PLUS: 2,
  PARTNER: 3,
} as const;

export const planIdToPlanName = (planId: number) => {
  const keys = Object.keys(subscriptionPlans) as (keyof typeof subscriptionPlans)[];
  return keys.find((key) => subscriptionPlans[key] === planId);
};

export type StripePrice = 'plusMonthly' | 'plusYearly';
// Map our custom plan IDs ("basic", "plus", etc) to Stripe price IDs
export const stripePriceIds: Record<StripePrice, string> = {
  plusMonthly: process.env.NEXT_PUBLIC_STRIPE_PRICE_PLUS_MONTHLY!,
  plusYearly: process.env.NEXT_PUBLIC_STRIPE_PRICE_PLUS_YEARLY!,
};

// Get friendly plan ID ("basic", "plus", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId?: string | null): StripePrice | undefined {
  if (!stripePriceId) {
    return undefined;
  }

  return Object.keys(stripePriceIds).find((key) => stripePriceIds[key as StripePrice] === stripePriceId) as StripePrice;
}

const roles = Object.values(UserRole);

export const isAtLeastRole = (userRole: UserRoleType | null, atLeastRole: UserRoleType | null): boolean => {
  // i think false makes sense?
  if (!userRole || !atLeastRole) return false;
  const userRoleIdx = roles.findIndex((role) => role === userRole);
  const atLeastRoleIdx = roles.findIndex((role) => role === atLeastRole);

  return userRoleIdx >= atLeastRoleIdx;
};
